<template>
    <v-app>
        <div class="mobileContainerMain">
            <slot />
        </div>
    </v-app>
</template>

<script>
export default {
    name: 'MainContainer',
};
</script>

<style>
.mobileContainerMain {
    margin-left: auto;
    margin-right: auto;
    width: 425px;
    background-color: #f7f7f7;
}
@media screen and (max-width: 425px){
.mobileContainerMain {
    width: 100%;
}
}
</style>